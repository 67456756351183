
import { Box } from '@mui/material';
import Logo from './Logo';
import LogoDark from './LogoDark';
import { Helmet } from 'react-helmet-async';
import useSettings from '../hooks/useSettings';

const SplashScreen = () => {
  const { settings } = useSettings();

  return (
  <>
    <Helmet>
      <title>FinPic® Platform</title>
    </Helmet>
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        p: 3,
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 2000
      }}
    >
      { settings.theme === 'FINPIC' && <LogoDark width={350} /> }
      { settings.theme === 'FPDARK' && <Logo width={350} /> }
    </Box>
  </>
  )
};

export default SplashScreen;
