import axios from 'axios';
import apiBaseUrl from './api/api';
import axiosConfig from './axiosConfig';

const subscriptionPlans = [
    {
        id: "prod_KLmczRNBrAgfAx",
        name: "Premium Plan",
        price: 149,
        recurring: "Monthly"
    },
    {
        id: "prod_KRH54CfGkNwkG4",
        name: "Standard Plan",
        price: 74.50,
        recurring: "Monthly"
    }
];

const reportPrices = [
    {
        id: "prod_KRHA83mSGgwUyh",
        name: 'premiumFinpic',
        label: "FinPic® Report - Premium Plan",
        price: 6.95
    },
    {
        id: "prod_KMBYGVjpXLy9kG",
        name: 'standardFinpic',
        label: "FinPic® Report - Standard Plan",
        price: 9.95
    }
];

const getPricingPlan = async (userEmail) => {
    let planObj = {};
    let reportObj = {};
    const response = await axios.get(apiBaseUrl + 'get_pricing_plan?userEmail=' + userEmail, axiosConfig);
    for (var i=0; i < subscriptionPlans.length; i++) {
        if(subscriptionPlans[i].id === response.data['ActiveSubscription']) {
            planObj = subscriptionPlans[i]
            reportObj = reportPrices[i];
        };
    };
    const pricingPlan = {
        planName: planObj['name'],
        planPrice: planObj['price'],
        planRecurring: planObj['recurring'],
        reportPrice: reportObj['price'],
        reportName: reportObj['label'],
        paymentMethod: response.data['PaymentMethod'],
        currency: response.data['Currency']
    };
    return pricingPlan;
};

export default getPricingPlan;