import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Hidden, Link, Typography } from '@mui/material';
import useAuth from '../../hooks/useAuth';
import ChartPieIcon from '../../icons/ChartPie';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import SubmitButton2 from '../buttons/SubmitButton2';

const sections = [
  {
    title: 'Reports',
    items: [
      {
        title: 'Overview',
        path: '/platform',
        icon: <ChartSquareBarIcon fontSize="small" />
      },
      {
        title: 'View All Reports',
        path: '/platform/reports/view-all',
        icon: <ChartPieIcon fontSize="small" />
      }
    ]
  },
  {
    title: 'Client Management',
    items: [
      {
        title: 'View All Clients',
        path: '/platform/clients/view-all'
      },
      {
        title: 'Create New Client',
        path: '/platform/clients/add-new'
      }
    ]
  },
  {
    title: 'Account & Platform',
    items: [
      {
        title: 'Profile',
        path: '/platform/account/profile'
      },
      {
        title: 'Billing',
        path: '/platform/account/billing'
      },
      {
        title: 'Activity & Notifications',
        path: '/platform/account/notifications'
      },
      {
        title: 'Account Security',
        path: '/platform/account/security'
      }
    ] 
  }
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const userPlan = props.userPlan;
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2
            }}
          >
            <RouterLink to="/">
              <Logo width={175} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <RouterLink to="/platform/account/profile">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="h6"
                sx={{
                  fontWeight: 500,
                  letterSpacing: 0
                }}
              >
                {user.name}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                Your plan:
                {' '}
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/platform/account/billing"
                >
                  {userPlan}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3,
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
        <Box sx={{ p: 2, pt: 4 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            Need Help?
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
            sx={{
              mt: 1
            }}
          >
            Check out our Documentation
          </Typography>
          <SubmitButton2
            color="primary"
            component={RouterLink}
            fullWidth
            sx={{ mt: 2, p: 1 }}
            to="/docs"
            variant="contained"
          >
            Visit Help Center
          </SubmitButton2>
        </Box>
      </Scrollbar>
    </Box>
  );

  return <>
    <Hidden lgUp>
      <Drawer
        anchor="left"
        onClose={onMobileClose}
        open={openMobile}
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            width: 280
          }
        }}
        variant="temporary"
      >
        {content}
      </Drawer>
    </Hidden>
    <Hidden xlDown>
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280
          }
        }}
        variant="persistent"
      >
        {content}
      </Drawer>
    </Hidden>
  </>;
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
