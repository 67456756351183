const filterUnreadNotifications = (notifications) => {
    const unreadNotifications = [];
    for (let i = 0; i < notifications.length; i++) {
      if (notifications[i]['read'] === false) {
        unreadNotifications.push(notifications[i]);
      }
    }
    return unreadNotifications;
};

export default filterUnreadNotifications;