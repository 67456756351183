
import Button from '@mui/material/Button';
import { styled } from '@mui/system';

const SubmitButton2 = styled(Button)({
    boxShadow: 'none',
    padding: '18px 25px',
    backgroundColor: '#2c3d4c',
    color: '#FFFFFF',
    '&:hover': {
        backgroundColor: '#ad1c4b'
    }
});

export default SubmitButton2;