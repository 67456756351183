import { useEffect, useRef, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import BellIcon from '../../icons/Bell';
import axios from 'axios';
import apiBaseUrl from '../../utils/api/api';
import axiosConfig from '../../utils/axiosConfig';
import useAuth from '../../hooks/useAuth';
import filterUnreadNotifications from '../../utils/notifications/filterUnreadNotifications';
import { formatDistanceToNow } from 'date-fns';

const NotificationsPopover = (props) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const userState = props.userState;
  let triggerRender = 0;
  const { user } = useAuth();

  useEffect(() => {
    const getNotifications = async () => {
      const response = await axios.get(apiBaseUrl + 'get_user_notifications', { params: { userId: user.id, limit: 10 } }, axiosConfig);
      setNotifications(response.data);
    };
    getNotifications();
    const unreadNotifications = filterUnreadNotifications(notifications);
    setUnreadNotifications(unreadNotifications);
  }, [triggerRender]
  );  


  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkAllRead = () => {
    axios.post(apiBaseUrl + 'mark_all_notifications_read?userId=' + userState['id'], axiosConfig);
    setUnreadNotifications([]);
    triggerRender += 1;
  }

  return <>
    <Tooltip title="Notifications">
      <IconButton color="inherit" ref={anchorRef} onClick={handleOpen} size="large">
        <Badge
          color="error"
          badgeContent={unreadNotifications.length}
        >
          <BellIcon fontSize="small" />
        </Badge>
      </IconButton>
    </Tooltip>
    <Popover
      anchorEl={anchorRef.current}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: { width: 320 }
      }}
    >
      <Box sx={{ p: 2 }}>
        <Typography
          color="textPrimary"
          variant="h5"
        >
          Notifications
        </Typography>
      </Box>
      {unreadNotifications.length === 0
        ? (
          <Box sx={{ p: 2 }}>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              There are no new notifications.
            </Typography>
          </Box>
        )
        : (
          <>
            <List disablePadding>
              {Array.isArray(notifications) && unreadNotifications.map((notification) => {
                return (
                  <ListItem
                    divider
                    key={unreadNotifications.indexOf(notification)}
                  >
                    <ListItemText
                      primary={notification.notification}
                      secondary={<Link
                        color="textPrimary"
                        sx={{ cursor: 'pointer', lineHeight: 1 }}
                        underline="none"
                        variant="subtitle2"
                      >
                        {notification.type}
                      </Link>}
                      sx={{ lineHeight: 0.75 }}
                    />
                    <Typography>{formatDistanceToNow(notification.dateCreated, {addSuffix: true})}</Typography>
                  </ListItem>
                );
              })}
            </List>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1
              }}
            >
              <Button
                color="primary"
                size="small"
                variant="text"
                onClick={() => {handleMarkAllRead()}}
              >
                Mark all as read
              </Button>
            </Box>
          </>
        )}
    </Popover>
  </>;
};

export default NotificationsPopover;
