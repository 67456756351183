
import { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

//  User pages 

const UserRegistration = Loadable(lazy(() => import('./pages/UserRegistration')));
const AccountSubscription = Loadable(lazy(() => import('./pages/AccountSubscription')));

//  Client pages

const Clients = Loadable(lazy(() => import('./pages/clients/ClientsList')));
const ClientDetails = Loadable(lazy(() => import('./pages/clients/ClientDetails')));
const ClientEdit = Loadable(lazy(() => import('./pages/clients/ClientEdit')));
const ClientAdd = Loadable(lazy(() => import('./pages/clients/ClientAdd')));
const ClientCredentialsCompleted = Loadable(lazy(() => import('./pages/ClientCredentialsCompleted')));

//  Report pages
const ReportContainer = Loadable(lazy(() => import('./pages/reports/ReportContainer')));
const Reports = Loadable(lazy(() => import('./pages/reports/ReportsList')));

const routes = [
  {
    path: '/',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    )
  },
  {
    path: 'account-setup/:id',
    element: (
      <UserRegistration />
    )
  },
  {
    path: 'account-subscription/:id',
    element: (
      <AccountSubscription />
    )
  },
  {
    path: 'success/:reportid',
    element: (
      <ClientCredentialsCompleted />
    )
  },
  {
    path: '401',
    element: <AuthorizationRequired />
  },
  {
    path: '404',
    element: <NotFound />
  },
  {
    path: '500',
    element: <ServerError />
  },
  {
    path: '*',
    element: <NotFound />
  },
  {
    path: 'platform',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Overview />
      },
      {
        path: 'account',
        children: [
          {
            path: 'profile',
            element: <Account selectedTab='general' />
          },
          {
            path: 'billing',
            element: <Account selectedTab='billing' />
          },
          {
            path: 'notifications',
            element: <Account selectedTab='notifications' />
          },
          {
            path: 'security',
            element: <Account selectedTab='security' />
          }
        ]
      },
      {
        path: 'client',
        children: [
          {
            path: ':id/profile',
            element: <ReportContainer selectedTab='profile' />
          },
          {
            path: ':id/finpic-report/',
            element: <ReportContainer selectedTab='finpic' />
          },
          {
            path: ':id/finpic-report/:reportId',
            element: <ReportContainer selectedTab='finpic'  />
          },
          {
            path: ':id/new-report',
            element: <ReportContainer selectedTab='new' />
          },
          {
            path: ':id/settings',
            element: <ReportContainer selectedTab='settings' />
          }
        ]
      },
      {
        path: 'clients',
        children: [
          {
            path: 'profile/:id',
            element: <ClientDetails />,
          },
          {
            path: 'view-all',
            element: <Clients />
          },
          {
            path: 'edit-profile/:id',
            element: <ClientEdit />
          },
          {
            path: 'add-new',
            element: <ClientAdd />
          }
        ]
      },
      {
        path: 'reports',
        children: [
          {
            path: ':id',
            element: <ReportContainer />
          },
          {
            path: 'profile/:id',
            element: <ReportContainer />
          },
          {
            path: 'view-all',
            element: <Reports />
          }
        ]
      }
    ]
  },
];

export default routes;
