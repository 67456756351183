import { atom } from 'recoil';

export const userInfo = atom({
    key: 'UserInfo',
    default: []
});

export const clientInfo = atom({
    key: 'ClientInfo',
    default: []
});

export const settingsTab = atom({
    key: 'SettingsTab',
    default: 'general'
});

export const selectedReport = atom({
    key: 'SelectedReport',
    default: ''
});

export const finPicReportTab = atom({
    key: 'FinPicReportTab',
    default: ''
});

export const pricingPlanInfo = atom({
    key: 'PricingPlan',
    default: []
});
