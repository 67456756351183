import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
    Box, 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogContentText, 
    DialogTitle, 
    Typography
} from '@mui/material';
import gtm from '../lib/gtm';

const SubscriptionErrorModal = (props) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(props.open);
    const [fullWidth, setFullWidth] = useState(false);
    const [maxWidth, setMaxWidth] = useState('md');
    const userId = props.userId;

    useEffect(() => {
        gtm.push({ event: 'inactive_subscription_modal' });
    }, []);

    return (
      <>
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          open={open}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle variant="h4"  id="max-width-dialog-title">
            Error
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ p: 2, color:'textPrimary', fontWeight: 700 }} >
              Sorry, it looks like your account does not have an active FinPic Platform suscription plan.  
            </DialogContentText>
            <Box
              noValidate
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                m: 'auto',
                width: 'fit-content',
              }}
            >
              <Typography 
                variant="body1"
                sx={{ my: 2 }}
              >
                  You can choose subscribe to a plan now or contact support below for help:
              </Typography>
              <Box
                sx={{
                  mb: 2
                }}
              > 
              <Button 
                  onClick={() => {navigate('/account-subscription/' + userId)}}
                  sx={{ mt: 2, mr:2, py: 2, px: 3, textTransform: 'capitalize', fontSize: '1.1em' }}
                  variant="contained"
                >
                  Join FinPic Platform Now
              </Button>
              <Button 
                  onClick={() => {navigate('/platform/support')}}
                  sx={{ mt: 2, py: 2, px: 3, textTransform: 'capitalize', fontSize: '1.1em' }}
                  variant="text"
                >
                  Contact Support 
              </Button>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </>
    );
  }

export default SubscriptionErrorModal;
