import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@mui/material';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import SubscriptionErrorModal from '../SubscriptionErrorModal';
import axios from 'axios';
import axiosConfig from '../../utils/axiosConfig';
import apiBaseUrl from '../../utils/api/api';
import getPricingPlan from '../../utils/stripe';
import useAuth from '../../hooks/useAuth';
import Drift from 'react-driftjs';
import { useRecoilState } from 'recoil';
import { pricingPlanInfo, userInfo } from '../../states/appStates';

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const DashboardLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: '64px',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: '280px'
  }
}));

const DashboardLayoutContainer = experimentalStyled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

const DashboardLayout = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
  const [userState, setUserState] = useRecoilState(userInfo);
  const [userId, setUserId] = useState(null);
  const [pricingPlan, setPricingPlan] = useRecoilState(pricingPlanInfo);
  const [clientData, setClientData] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const { user } = useAuth();
  const now = Math.floor(Date.now() / 1000) + 86400 // Adding 1 Day as a grace period.
  const [subscriptionPeriodEnd, setSubscriptionPeriodEnd] = useState(now + 86400);

  console.log('user', userState);

  useEffect(() => {
    const getUserData = async () => {
      const response = await axios.get(apiBaseUrl + 'get_user', { params: { userId: user.id } }, axiosConfig);
      setUserId(response.data['id']);
      setSubscriptionPeriodEnd(response.data['subscriptionPeriodEnd'])
      setUserState(response.data);
    };
    getUserData();
    if (userState !== []) {
      getPricingPlan(user.email).then((pricingPlan) => setPricingPlan(pricingPlan));
    }
  }, []
  );

  useEffect(() => {
    const getRecentClients = async () => {
      const response = await axios.get(apiBaseUrl + 'get_recent_clients', { params: { owner: user.id, limit: 5 } }, axiosConfig);
      setClientData(response.data);
    };
    getRecentClients();
  }, []
  );

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)} userState={userState} clientData={clientData} notifications={notifications} />
      <DashboardSidebar
        onMobileClose={() => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
        userPlan={userState['subscriptionPlanType']}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet notifications={notifications} />
            <Drift appId="a69d7i6f2ayk" />
            { subscriptionPeriodEnd < now && <SubscriptionErrorModal userId={userId} open={true} /> }
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
