
import { Box, Chip, Container, Link, Typography } from '@mui/material';

const AuthBanner = () => (
  <Box
    sx={{
      backgroundColor: 'background.paper',
      borderBottom: 1,
      borderColor: 'divider',
      py: 2
    }}
  >
    <Container maxWidth="md">
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Chip
          color="primary"
          label="GET STARTED NOW"
          size="medium"
        />
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            ml: 2,
            '& > img': {
              height: 30,
              mx: 2
            }
          }}
        >
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            <Link
              href="https://calendly.com/finpic/discovery"
            >
              Schedule a Demo today
            </Link>
            {' '}
            and learn how FinPic® can help your business.
          </Typography>
        </Box>
      </Box>
    </Container>
  </Box>
);

export default AuthBanner;
