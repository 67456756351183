import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Divider, Link, Typography } from '@mui/material';
import AuthBanner from '../../components/authentication/AuthBanner';
import {
  LoginAmplify,
  LoginAuth0,
  LoginFirebase,
  LoginJWT
} from '../../components/authentication/login';
import Logo from '../../components/Logo';
import LogoDark from '../../components/LogoDark';
import useAuth from '../../hooks/useAuth';
import gtm from '../../lib/gtm';
import useSettings from '../../hooks/useSettings';
import axios from 'axios';
import apiBaseUrl from '../../utils/api/api';

const platformIcons = {
  Amplify: '/static/icons/amplify.svg',
  Auth0: '/static/icons/auth0.svg',
  Firebase: '/static/icons/firebase.svg',
  JWT: '/static/icons/jwt.svg'
};

const Login = () => {
  const { platform } = useAuth();

  useEffect(() => {
    gtm.push({ event: 'login_page_view' });
  }, []);

  useEffect(() => {
    const wakeUpServer = async () => {
      await axios.post(apiBaseUrl + 'wake_up');
    };
    wakeUpServer();
  }, []);

  const settings = useSettings();

  return (
    <>
      <Helmet>
        <title>Login | FinPic® Platform</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '150vh'
        }}
      >
        <AuthBanner />
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 5
            }}
          >
            <RouterLink to="https://finpic.com">
              { settings.settings.theme === 'FINPIC' && <LogoDark width={350} /> }
              { settings.settings.theme === 'FPDARK' && <Logo width={240} /> }
            </RouterLink>
          </Box>
          <Card sx={{ boxShadow: 2 }}>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                px: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  my: 2
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h3"
                  >
                    Login
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    Log into the FinPic® Platform by clicking the Log In button below.
                  </Typography>
                </div>
                <Box
                  sx={{
                    height: 32,
                    '& > img': {
                      maxHeight: '100%',
                      width: 'auto'
                    }
                  }}
                >
                  <img
                    alt="Auth platform"
                    src={platformIcons[platform]}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 1
                }}
              >
                {platform === 'Amplify' && <LoginAmplify />}
                {platform === 'Auth0' && <LoginAuth0 />}
                {platform === 'Firebase' && <LoginFirebase />}
                {platform === 'JWT' && <LoginJWT />}
              </Box>
              <Divider sx={{ my: 3 }} />
              {platform === 'Amplify' && (
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  sx={{ mt: 1 }}
                  to="/authentication/password-recovery"
                  variant="body2"
                >
                  Forgot password
                </Link>
              )}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
