
import Button from '@mui/material/Button';
import { styled } from '@mui/system';

const SubmitButton3 = styled(Button)({
    boxShadow: 'none',
    fontWeight: 500,
    padding: '0px',
    backgroundColor: '#FFFFFF',
    color: '#000000',
    borderRadius: '8px',
    fontSize: '17px',
    textTransform: 'none',
    transition: 'all .3s ease-in-out',
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'center',
    border: '2px #000 solid',
    '&:hover': {
        backgroundColor: '#000000',
        color: '#FFFFFF'
    }
});

export default SubmitButton3;